module.exports = {
  addProductByScan: "addProductByScan",
  addProductManually: "addProductManually",
  authBan: "authBan",
  baseDocumentaire: "baseDocumentaire",
  communityEntityInterventions: "communityEntityInterventions",
  communityTour: "communityTour",
  customProtocol: "customProtocol",
  deliveryNote: "deliveryNote",
  emailNotifications: "emailNotifications",
  extranet: "extranet",
  fastpay: "fastpay",
  faxes: "faxes",
  interventionReport: "interventionReport",
  interventions: "interventions",
  liquidOxygen: "liquidOxygen",
  mailToPatientForNewAlert: "mailToPatientForNewAlert",
  maintenance: "maintenance",
  messaging: "messaging",
  offlinePdf: "offlinePdf",
  optitime: "optitime",
  ordoclic: "ordoclic",
  patientSignature: "patientSignature",
  patientsOrientedMessages: "patientsOrientedMessages",
  patientTelesuiviSynchroButton: "patientTelesuiviSynchroButton",
  prescriberAccount: "prescriberAccount",
  productOrigin: "productOrigin",
  productsReminder: "productsReminder",
  questionnaires: "questionnaires",
  reminders: "reminders",
  riskAnalysis: "riskAnalysis",
  selectMessageTypeOnExtranet: "selectMessageTypeOnExtranet",
  sleepStudies: "sleepStudies",
  stockHandler: "stockHandler",
  support: "support",
  synchroPatient: "synchroPatient",
  synchroStocks: "synchroStocks",
  telesuiviAlerts: "telesuiviAlerts",
  woocommerce: "woocommerce",

  // PLANIFICATION
  planificationGlobal: "planification.global",
  planificationActionButtons: "planification.actionButtons",
  planificationOnContractCreation: "planification.onContractCreation",
  planificationOnInterventionAchieved: "planification.onInterventionAchieved",

  // SMS
  smsService: "sms.service",
  smsSynchro: "sms.synchro",
  smsAuto: "sms.auto",
  smsOnRequest: "sms.onRequest",

  // AMELIPRO
  amelipro: "amelipro",

  // INTERVENTION
  interventionValidation: "interventionValidation",
  interventionRescheduleImpossible: "interventionRescheduleImpossible"
};
