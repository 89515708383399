const get = require("lodash/get");

const isPilot = require("@common/helpers/pilot/isPilot");

const flags = require(`@common/services/features/flags.${__PLATFORM__}`);
const featureTypes = require("@common/constants/featureTypes");

const pilotLiquidOxygen = require("@common/constants/pilots/pilotLiquidOxygen");
const pilotAmelipro = require("@common/constants/pilots/pilotAmelipro");
const buildPilotOptions = require("@common/helpers/pilot/buildPilotOptions");

/*
This file exists both in client and server because we need to have acces to the platform constant (client : __PLATFORM__ | server : process.env.PLATFORM)

We can't mutualized this file because neither of those variable are accessible in the common folder
*/

const isAddProductByScanEnabled = () => {
  return get(flags, featureTypes.addProductByScan);
};

const isAddProductManuallyEnabled = () => {
  return get(flags, featureTypes.addProductManually);
};

const isAuthBanEnabled = () => {
  return get(flags, featureTypes.authBan);
};

const isBaseDocumentaireEnabled = () => {
  return get(flags, featureTypes.baseDocumentaire);
};

const isCommunityEntityInterventionsEnabled = () => {
  return get(flags, featureTypes.communityEntityInterventions);
};

const isCommunityTourEnabled = () => {
  return get(flags, featureTypes.communityTour);
};

const isDeliveryNoteEnabled = () => {
  return get(flags, featureTypes.deliveryNote);
};

const isFastpayEnabled = () => {
  return get(flags, featureTypes.fastpay);
};

const isFaxesEnabled = () => {
  return get(flags, featureTypes.faxes);
};

const isInterventionsEnabled = () => {
  return get(flags, featureTypes.interventions);
};

const isInterventionReportEnabled = () => {
  return get(flags, featureTypes.interventionReport);
};

const isMaintenanceEnabled = () => {
  return get(flags, featureTypes.maintenance);
};

const isMessagingEnabled = () => {
  return get(flags, featureTypes.messaging);
};

const isOfflinePdfEnabled = () => {
  return get(flags, featureTypes.offlinePdf);
};

const isOrdoclicEnabled = () => {
  return get(flags, featureTypes.ordoclic);
};

const isPatientsOrientedConversationsEnabled = () => {
  return get(flags, featureTypes.patientsOrientedMessages);
};

const isPatientSignatureEnabled = () => {
  return get(flags, featureTypes.patientSignature);
};

const isPlanificationActionButtonsEnabled = () => {
  return get(flags, featureTypes.planificationActionButtons);
};

const isPatientTelesuiviSynchroButtonEnabled = () => {
  return get(flags, featureTypes.patientTelesuiviSynchroButton);
};

const isPlanificationGlobalEnabled = () => {
  return get(flags, featureTypes.planificationGlobal);
};

const isPlanificationOnContractCreationEnabled = () => {
  return get(flags, featureTypes.planificationOnContractCreation);
};

const isPlanificationOnInterventionAchievedEnabled = () => {
  return get(flags, featureTypes.planificationOnInterventionAchieved);
};

const isProductOriginEnabled = () => {
  return get(flags, featureTypes.productOrigin);
};

const isQuestionnairesEnabled = () => {
  return get(flags, featureTypes.questionnaires);
};

const isRemindersEnabled = () => {
  return get(flags, featureTypes.reminders);
};

const isRiskAnalysisEnabled = () => {
  return get(flags, featureTypes.riskAnalysis);
};

const isSelectMessageTypeOnExtranetEnabled = () => {
  return get(flags, featureTypes.selectMessageTypeOnExtranet);
};

const isSleepStudiesEnabled = () => {
  return get(flags, featureTypes.sleepStudies);
};

const isStockHandlerEnabled = () => {
  return get(flags, featureTypes.stockHandler);
};

const isSupportEnabled = () => {
  return get(flags, featureTypes.support);
};

const isSynchroPatientEnabled = () => {
  return get(flags, featureTypes.synchroPatient);
};

const isSynchroStocksEnabled = () => {
  return get(flags, featureTypes.synchroStocks);
};

const isTelesuiviAlertsEnabled = () => {
  return get(flags, featureTypes.telesuiviAlerts);
};

const isWoocommerceEnabled = () => {
  return get(flags, featureTypes.woocommerce);
};

const isOptitimeEnabled = () => {
  return get(flags, featureTypes.optitime);
};

// AMELIPRO
// isAmeliproEnabled is use to check if the feature is enabled
const isAmeliproEnabled = (entity = {}) => {
  if (get(flags, featureTypes.amelipro)) {
    return isPilot(buildPilotOptions(entity), pilotAmelipro);
  }
  return false;
};

// isAmeliproActivated is use to check if the current entity have deactived the feature
const isAmeliproActivated = (entity = {}) => {
  if (entity.deactivateAmelipro) {
    return false;
  }

  return isAmeliproEnabled(entity);
};

// LIQUID
const isLiquidOxygenEnabled = (entity = {}) => {
  if (get(flags, featureTypes.liquidOxygen)) {
    return isPilot(buildPilotOptions(entity), pilotLiquidOxygen);
  }

  return false;
};

// SMS
const isSmsServiceEnabled = () => {
  return get(flags, featureTypes.smsService);
};
const isSmsAutoEnabled = () => {
  return get(flags, featureTypes.smsAuto);
};
const isSmsSynchroEnabled = () => {
  return get(flags, featureTypes.smsSynchro);
};
const isSmsOnRequestEnabled = () => {
  return get(flags, featureTypes.smsOnRequest);
};

// INTERVENTION
const isInterventionValidationEnabled = () => {
  return get(flags, featureTypes.interventionValidation);
};

module.exports = {
  isAddProductByScanEnabled,
  isAddProductManuallyEnabled,
  isAuthBanEnabled,
  isBaseDocumentaireEnabled,
  isCommunityEntityInterventionsEnabled,
  isCommunityTourEnabled,
  isDeliveryNoteEnabled,
  isFastpayEnabled,
  isFaxesEnabled,
  isInterventionsEnabled,
  isInterventionReportEnabled,
  isMaintenanceEnabled,
  isMessagingEnabled,
  isOfflinePdfEnabled,
  isOrdoclicEnabled,
  isPatientsOrientedConversationsEnabled,
  isPatientSignatureEnabled,
  isPlanificationActionButtonsEnabled,
  isPatientTelesuiviSynchroButtonEnabled,
  isPlanificationGlobalEnabled,
  isPlanificationOnContractCreationEnabled,
  isPlanificationOnInterventionAchievedEnabled,
  isProductOriginEnabled,
  isQuestionnairesEnabled,
  isRemindersEnabled,
  isRiskAnalysisEnabled,
  isSelectMessageTypeOnExtranetEnabled,
  isSleepStudiesEnabled,
  isStockHandlerEnabled,
  isSupportEnabled,
  isSynchroPatientEnabled,
  isSynchroStocksEnabled,
  isTelesuiviAlertsEnabled,
  isWoocommerceEnabled,
  isOptitimeEnabled,
  isAmeliproEnabled,
  isAmeliproActivated,
  isLiquidOxygenEnabled,
  isSmsServiceEnabled,
  isSmsAutoEnabled,
  isSmsSynchroEnabled,
  isSmsOnRequestEnabled,
  isInterventionValidationEnabled
};
