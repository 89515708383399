import moment from "moment";
import {
  fetchPlanningInterventions,
  fetchUserEvents,
  searchPatients,
  createUserEvent,
  deleteUserEvent,
  updateUserEvent,
  fetchIntervenants
} from "@/modules/common/_api";
import { fetchEntityInterventions } from "@/modules/entity-interventions/common/_api";
import formatUserEventData from "@/helpers/_functions/userEvent/formatUserEventData";
import * as api from "../_api";

const state = {
  technicians: [],
  interventions: [],
  userEvents: [],
  entityInterventions: [],
  currentDivisions: ["R"],
  currentCount: {
    technicians: 0,
    interventions: 0,
    userEvents: 0
  },
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  lastRequestId: null,
  lastInterventionRequestId: null,
  formattedQuery: {},
  formattedContentQuery: {}
};

const getters = {
  technicians: state => state.technicians,
  interventions: state => state.interventions,
  userEvents: state => state.userEvents,
  entityInterventions: state => state.entityInterventions,
  currentDivisions: state => state.currentDivisions,
  currentCount: state => state.currentCount,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery,
  formattedContentQuery: state => state.formattedContentQuery
};

const mutations = {
  SET_TECHNICIANS: (state, technicians) => {
    state.technicians = technicians;
  },
  SET_INTERVENTIONS: (state, interventions) => {
    state.interventions = interventions;
  },
  SET_USER_EVENTS: (state, userEvents) => {
    state.userEvents = userEvents;
  },
  SET_ENTITY_INTERVENTIONS: (state, entityInterventions) => {
    state.entityInterventions = entityInterventions;
  },
  SET_CURRENT_DIVISIONS: (state, divisions) => {
    state.currentDivisions = divisions;
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, { type, count }) => {
    state.currentCount[type] = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_FORMATTED_CONTENT_QUERY: (state, formattedContentQuery) => {
    state.formattedContentQuery = formattedContentQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_LAST_INTERVENTIONS_REQUEST_ID: (state, requestId) => {
    state.lastInterventionsRequestId = requestId;
  }
};

const actions = {
  fetchIntervenants(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivisions } = context.state;
      fetchIntervenants({ ...params, query: { ...formattedQuery, divisions: currentDivisions, active: true } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_TECHNICIANS", results);
          context.commit("SET_CURRENT_COUNT", { type: "technicians", count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPlanningInterventions(context, { requestId, query, ...params }) {
    context.commit("SET_LAST_INTERVENTIONS_REQUEST_ID", requestId);

    return new Promise((resolve, reject) => {
      const { formattedContentQuery } = context.state;

      fetchPlanningInterventions({ query: { ...query, ...formattedContentQuery }, ...params })
        .then(res => {
          const { lastInterventionsRequestId } = context.state;

          if (lastInterventionsRequestId !== requestId) {
            resolve();
            return;
          }
          context.commit("SET_INTERVENTIONS", res.data?.body?.results || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchUserEvents(context, { query, ...params }) {
    return new Promise((resolve, reject) => {
      const { formattedContentQuery } = context.state;
      fetchUserEvents({ query: { ...query, ...formattedContentQuery }, ...params })
        .then(res => {
          context.commit("SET_USER_EVENTS", res.data?.body || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchEntityInterventions(context, params) {
    return new Promise((resolve, reject) => {
      fetchEntityInterventions(params)
        .then(res => {
          context.commit("SET_ENTITY_INTERVENTIONS", res.data?.body?.results || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { interventionStatusIds, ...formattedQuery } = query;
    const formattedContentQuery = {};
    if (interventionStatusIds && interventionStatusIds.length) {
      formattedContentQuery.interventionStatusIds = interventionStatusIds;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
    context.commit("SET_FORMATTED_CONTENT_QUERY", formattedContentQuery);
  },
  setCurrentDivisions(context, divisions) {
    context.commit("SET_CURRENT_DIVISIONS", divisions);
  },
  getTechniciansCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getTechniciansCount(params)
        .then(res => {
          context.commit("SET_COUNT", { division: params.query.divisionId.toLowerCase(), count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchPatients(context, input) {
    return searchPatients({ query: { input, contractsActive: true }, skip: 0, limit: 40, source: "local" });
  },
  updateInterventionsAndEvents(context, { interventions, entityInterventions, userEvents }) {
    const promises = [];
    if (interventions && interventions.length) {
      const promisesInterventions = interventions.map(intervention => {
        const plannedDateTimeEnd = moment(intervention.plannedDateTimeStart).add(1, "hours");
        return api.updateInterventionPlanning(intervention._id, {
          statusId: intervention.statusId,
          plannedDateTimeStart: intervention.plannedDateTimeStart,
          plannedDateTimeEnd,
          intervenantId: intervention.intervenantId,
          intervenantExternalId: intervention.intervenantExternalId
        });
      });
      promises.push(...promisesInterventions);
    }
    if (entityInterventions && entityInterventions.length) {
      const promisesEntityInterventions = entityInterventions.map(entityIntervention => {
        const plannedDateTimeEnd = moment(entityIntervention.plannedDateTimeStart).add(1, "hours");
        return api.updateEntityInterventionPlanning(entityIntervention._id, {
          statusId: entityIntervention.statusId,
          plannedDateTimeStart: entityIntervention.plannedDateTimeStart,
          plannedDateTimeEnd,
          intervenantId: entityIntervention.intervenantId,
          intervenantExternalId: entityIntervention.intervenantExternalId
        });
      });
      promises.push(...promisesEntityInterventions);
    }
    if (userEvents && userEvents.length) {
      const promisesUserEvents = userEvents.map(({ _id, ...userEvent }) => {
        return updateUserEvent(_id, formatUserEventData(userEvent));
      });
      promises.push(...promisesUserEvents);
    }
    return Promise.all(promises);
  },
  createUserEvent(context, userEvent) {
    return createUserEvent(formatUserEventData(userEvent));
  },
  deleteUserEvent(context, userEventId) {
    return deleteUserEvent(userEventId);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
