import cloneDeep from "lodash/cloneDeep";
import * as uuid from "uuid";

import ameliproTransmissionStates from "@common/constants/amelipro/ameliproTransmissionStates";

import { getOrdoclicIframeInfos, handleAmeliproNewTransmission, handleAmeliproTransmissionError, logAmeliproTransmission } from "../_api";

import { updateAmeliproPrescriptionStatus } from "../../renewals/_api";

import formatAmeliproBatchOperationsForPrescriptionStatusUpdate from "@/helpers/_functions/amelipro/formatAmeliproBatchOperationsForPrescriptionStatusUpdate";
import buildAmeliproPrescriptionStatusFromBatch from "@/helpers/_functions/amelipro/buildAmeliproPrescriptionStatusFromBatch";

const state = {
  // Transmission Infos
  transmissionStep: 0,
  transmissionTotalSteps: 0,
  transmissionMessageId: null,
  transmissionState: ameliproTransmissionStates.notStarted, // notStarted | inProgress | stopped | finished | failed

  // Batch Infos
  currentBatchMessageId: null,
  batchOperations: [],
  prescriptionInfosBeforeBatchOperations: [],
  batchOperationMessageIdsCompleted: [],
  nbTotalBatchOperations: 0,
  nbBatchOperationsCompleted: 0,

  // New transmission triggers values
  newTransmissionRequest: false,
  newTransmissionRequestFormType: null,
  newTransmissionRequestFormData: null,

  // Transmission Options
  autoSubmission: true,
  transmissionType: "uniq",

  // Transmission result value
  transmissionResult: null,

  // Transmission error
  transmissionError: null
};

const getters = {
  transmissionInfos: state => {
    return {
      id: state.transmissionType === "batch" ? state.currentBatchMessageId : state.transmissionMessageId,
      step: state.transmissionStep,
      totalSteps: state.transmissionTotalSteps,
      nbOperationsCompleted: state.nbBatchOperationsCompleted,
      nbTotalOperations: state.nbTotalBatchOperations,
      state: state.transmissionState,
      type: state.transmissionType
    };
  },
  batchInfos: state => {
    const operationMessageIds = state.prescriptionInfosBeforeBatchOperations.map(operation => operation.messageId);
    const operationMessageIdsCompleted = state.batchOperationMessageIdsCompleted;

    return {
      operationMessageIds,
      operationMessageIdsCompleted,
      areAllOperationsCompleted: operationMessageIds.length === operationMessageIdsCompleted.length
    };
  },
  transmissionResult: state => {
    return state.transmissionResult;
  },
  transmissionError: state => {
    return state.transmissionError;
  },
  triggerNewTransmission: state => {
    if (state.transmissionMessageId) {
      return false;
    }
    return state.newTransmissionRequest;
  },
  isTransmissionInProgress: state => {
    return state.transmissionState === ameliproTransmissionStates.inProgress;
  }
};

const mutations = {
  INIT_TRANSMISSION_STORE: state => {
    state.transmissionType = "uniq";
    state.transmissionStep = 0;
    state.transmissionTotalSteps = 0;
    state.transmissionMessageId = null;

    state.currentBatchMessageId = null;
    state.batchOperations = [];
    state.prescriptionInfosBeforeBatchOperations = [];
    state.batchOperationMessageIdsCompleted = [];
    state.nbBatchOperationsCompleted = 0;
    state.nbTotalBatchOperations = 0;

    state.newTransmissionRequest = false;
    state.newTransmissionRequestFormType = null;
    state.newTransmissionRequestFormData = null;

    state.transmissionResult = null;
    state.transmissionError = null;

    state.transmissionState = ameliproTransmissionStates.notStarted;
  },
  START_TRANSMISSION: (state, newTransmission) => {
    state.transmissionMessageId = newTransmission;

    state.transmissionResult = null;
    state.transmissionError = null;

    state.transmissionState = ameliproTransmissionStates.notStarted;
  },
  UPDATE_TRANSMISSION_STATE: (state, { transmissionMessageId, totalSteps, step, transmissionState }) => {
    state.transmissionMessageId = transmissionMessageId;
    state.transmissionTotalSteps = totalSteps;
    state.transmissionStep = step;

    state.transmissionState = transmissionState;
  },
  UPDATE_NEW_TRANSMISSION_REQUEST: (state, { requestFormType, requestFormData, autoSubmission }) => {
    state.newTransmissionRequest = true;
    state.newTransmissionRequestFormType = requestFormType;
    state.newTransmissionRequestFormData = requestFormData;
    state.transmissionType = "uniq";

    state.autoSubmission = autoSubmission;

    state.transmissionStep = 0;
    state.transmissionTotalSteps = 0;

    state.transmissionState = ameliproTransmissionStates.notStarted;
  },
  INIT_BATCH_INFOS: state => {
    state.transmissionType = "batch";

    state.currentBatchMessageId = uuid.v4();

    state.transmissionStep = 0;
    state.transmissionTotalSteps = 0;
    state.nbBatchOperationsCompleted = 0;
    state.nbTotalBatchOperations = 0;

    state.batchOperations = [];
    state.prescriptionInfosBeforeBatchOperations = [];
    state.batchOperationMessageIdsCompleted = [];
  },
  UPDATE_BATCH_OPERATIONS: (state, { batchOperations, prescriptionInfosBeforeBatchOperations }) => {
    state.batchOperations = batchOperations;
    state.prescriptionInfosBeforeBatchOperations = prescriptionInfosBeforeBatchOperations;

    state.nbTotalBatchOperations = prescriptionInfosBeforeBatchOperations.length || 0;
  },
  INSERT_BATCH_OPERATIONS_MESSAGE_ID_COMPLETED: (state, operationMessageIdCompleted) => {
    state.batchOperationMessageIdsCompleted.push(operationMessageIdCompleted);

    state.nbBatchOperationsCompleted = state.batchOperationMessageIdsCompleted.length || 0;
  },
  RESET_NEW_TRANSMISSION_REQUEST: state => {
    state.transmissionType = "uniq";
    state.newTransmissionRequest = false;
    state.newTransmissionRequestFormType = null;
    state.newTransmissionRequestFormData = null;

    state.transmissionMessageId = null;
    state.transmissionStep = 0;
    state.transmissionTotalSteps = 0;

    state.currentBatchMessageId = null;
    state.batchOperations = [];
    state.prescriptionInfosBeforeBatchOperations = [];
    state.batchOperationMessageIdsCompleted = [];
    state.nbBatchOperationsCompleted = 0;
    state.nbTotalBatchOperations = 0;

    state.transmissionState = ameliproTransmissionStates.notStarted;
  },
  UPDATE_TRANSMISSION_RESULT: (state, result) => {
    state.transmissionResult = result;

    state.transmissionState = ameliproTransmissionStates.finished;
  },
  UPDATE_BATCH_RESULT: (state, result) => {
    state.batchResult = result;

    state.transmissionState = ameliproTransmissionStates.finished;
  },
  UPDATE_TRANSMISSION_ERROR: (state, { error, transmissionState }) => {
    state.transmissionError = error;

    state.transmissionState = transmissionState;
  }
};

const actions = {
  getOrdoclicIframeInfos(context, { rpps, intent }) {
    return new Promise((resolve, reject) => {
      getOrdoclicIframeInfos({ rpps, intent })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  initTransmissionState(context) {
    context.commit("INIT_TRANSMISSION_STORE");
  },
  async startNewTransmission(context, { iframe, rpps, prescriberId, patientId, contractId }) {
    const message = {
      messageId: uuid.v4(),
      messageType: "amelipro.transmission",
      payload: {
        formType: context.state.newTransmissionRequestFormType,
        autoSubmission: context.state.autoSubmission,
        inputs: context.state.newTransmissionRequestFormData
      }
    };

    const cleanedTransmission = cloneDeep(message);
    Object.assign(cleanedTransmission, { rpps, prescriberId, patientId, contractId });
    delete cleanedTransmission.payload.inputs.dap?.compteRendu?.content;
    delete cleanedTransmission.payload.inputs.dap?.changementTraitementPieceJointe?.content;

    await handleAmeliproNewTransmission(cleanedTransmission);

    iframe.contentWindow.postMessage(message, "*");

    console.log(`amelipro-transmission ${message.messageId} Sent with payload : `, {
      formType: context.state.newTransmissionRequestFormType,
      autoSubmission: context.state.autoSubmission,
      inputs: context.state.newTransmissionRequestFormData
    });
  },
  startNewBatch(context, { iframe }) {
    const message = {
      messageId: context.state.currentBatchMessageId,
      messageType: "amelipro.batch",
      payload: {
        batch: context.state.batchOperations
      }
    };

    iframe.contentWindow.postMessage(message, "*");

    console.log(`amelipro-batch ${message.messageId} Sent with payload : `, context.state.batchOperations);
  },
  initAmeliproPrescriptionsStatusVerification(context, { iframe, prescriptionsToCheck, autoStart = false }) {
    context.dispatch("resetNewTransmission");

    context.commit("INIT_BATCH_INFOS");
    context.commit("UPDATE_BATCH_OPERATIONS", formatAmeliproBatchOperationsForPrescriptionStatusUpdate(prescriptionsToCheck));

    if (autoStart) {
      context.dispatch("startNewBatch", { iframe });
    }
  },
  requestNewTransmission(context, { requestFormType, requestFormData, autoSubmission = true }) {
    context.dispatch("resetNewTransmission");

    context.commit("UPDATE_NEW_TRANSMISSION_REQUEST", {
      requestFormType,
      requestFormData,
      autoSubmission
    });
  },
  resetNewTransmission(context) {
    context.commit("RESET_NEW_TRANSMISSION_REQUEST");
  },
  updateTransmission(context, { transmissionMessageId, totalSteps, step }) {
    context.commit("UPDATE_TRANSMISSION_STATE", {
      transmissionMessageId,
      totalSteps,
      step,
      transmissionState: ameliproTransmissionStates.inProgress
    });
  },
  updateBatchOperationsCompleted(context, operationMessageIdCompleted) {
    if (!context.state.batchOperationMessageIdsCompleted.includes(operationMessageIdCompleted)) {
      context.commit("INSERT_BATCH_OPERATIONS_MESSAGE_ID_COMPLETED", operationMessageIdCompleted);
    }
  },
  async endTransmission(context, { payload, transmissionState }) {
    console.log("[STORE-IFRAME/ACTIONS] endTransmission ", { payload, transmissionState });

    // Transmission success
    if (transmissionState === ameliproTransmissionStates.finished) {
      context.commit("UPDATE_TRANSMISSION_RESULT", payload.form);

      return;
    }

    if (payload.batch && context.state.prescriptionInfosBeforeBatchOperations.length > 0) {
      const newPrescriptionAmeliproStatusToUpdate = buildAmeliproPrescriptionStatusFromBatch(
        payload.batch,
        context.state.prescriptionInfosBeforeBatchOperations
      );

      if (newPrescriptionAmeliproStatusToUpdate?.length > 0) {
        await newPrescriptionAmeliproStatusToUpdate.forEach(async dataToUpdate => {
          const { prescriptionId, ...data } = dataToUpdate;
          await updateAmeliproPrescriptionStatus(prescriptionId, data);
        });
      }

      context.commit("UPDATE_BATCH_RESULT", { prescriptionsAmeliproUpdated: newPrescriptionAmeliproStatusToUpdate.length });

      return;
    }

    // Transmission Error
    await handleAmeliproTransmissionError(payload.transmissionMessageId, payload.error);

    context.commit("UPDATE_TRANSMISSION_ERROR", { error: payload.error, transmissionState });
  },
  logAmeliproTransmission(context, data) {
    const dataToLog = { ...data };

    if (dataToLog.payload?.transmissionMessageId) {
      dataToLog.messageId = dataToLog.payload.transmissionMessageId;
    }
    logAmeliproTransmission(dataToLog);
  }
};

export default { state, getters, mutations, actions };
