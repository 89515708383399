/* eslint-disable import/no-cycle */

import Vue from "vue";
import Vuex from "vuex";

// Modules
import versionManager from "@/services/versionManager/_store";
import ordoclic from "@/services/ordoclic/_store";
import appInfos from "@/services/appInfos/_store";
import synchroStatuses from "@/services/synchro-statuses/_store";

import supportLogs from "@/modules/support-logs/_store";

import supportTickets from "@/modules/support-tickets/list/_store";
import supportTicketsAdd from "@/modules/support-tickets/add/_store";
import supportTicketsView from "@/modules/support-tickets/view/_store";

import credentials from "@/modules/credentials/_store";
import login from "@/modules/login/_store/index.admin";

import caisses from "@/modules/caisses/_store";
import dashboard from "@/modules/dashboard/_store/index.admin";

import payment from "@/modules/payment/_store";

import hsat from "@/modules/hsat/_store";

import patientOrders from "@/modules/patient-orders/_store";

import patientLiftFormView from "@/modules/patient-lift-form/view/_store";
import patients from "@/modules/patients/_store";
import patientView from "@/modules/patient/view/_store";
import patientAdd from "@/modules/patient/add/_store";
import patientEdit from "@/modules/patient/edit/_store";
import patientPlanification from "@/modules/patient/planification/_store";
import patientSearch from "@/modules/patient/search/_store";

import prescriberAdd from "@/modules/prescriber/add/_store";
import prescribersList from "@/modules/prescribers/_store";
import prescriberView from "@/modules/prescriber/view/_store";
import prescriberEdit from "@/modules/prescriber/edit/_store";

import noticesAdd from "@/modules/noticesManager/add/_store";
import noticesList from "@/modules/noticesManager/list/_store";

import communityAdd from "@/modules/community/add/_store";
import communities from "@/modules/communities/_store";
import communityView from "@/modules/community/view/_store";
import communityEdit from "@/modules/community/edit/_store";

import communitiesGroups from "@/modules/communities-groups/_store";
import communitiesGroupAdd from "@/modules/communities-group/add/_store";
import communitiesGroupView from "@/modules/communities-group/view/_store";
import communitiesGroupEdit from "@/modules/communities-group/edit/_store";

import interventions from "@/modules/interventions/list/_store";
import interventionsPlanning from "@/modules/interventions/planning/_store";
import interventionView from "@/modules/interventions/view/_store";
import interventionsTour from "@/modules/interventions/tour/_store";
import interventionsCalendar from "@/modules/interventions/calendar/_store";
import interventionsCommon from "@/modules/interventions/common/_store";

import prescribersGroups from "@/modules/prescribers-groups/_store";
import prescribersGroupAdd from "@/modules/prescribers-group/add/_store";
import prescribersGroupEdit from "@/modules/prescribers-group/edit/_store";
import prescribersGroupView from "@/modules/prescribers-group/view/_store";

import usersList from "@/modules/users/list/_store";
import usersView from "@/modules/users/view/_store";
import usersAdd from "@/modules/users/add/_store";
import usersEdit from "@/modules/users/edit/_store";

import products from "@/modules/products/list/_store";
import productView from "@/modules/products/view/_store";
import productEdit from "@/modules/products/edit/_store";
import productsStock from "@/modules/products/stock/_store";
import hsatStocks from "@/modules/products/hsat-stock/_store";

import gasOxygenMonitoring from "@/modules/products/gas-oxygen-monitoring/_store";
import gasOxygenHistory from "@/modules/products/gas-oxygen-history/_store";

import liquidOxygenMonitoring from "@/modules/products/liquid-oxygen-monitoring/_store";
import liquidOxygenFraction from "@/modules/products/liquid-oxygen-fraction/_store";

import contractsAdd from "@/modules/contracts/add/_store";

import aald from "@/modules/aald/_store";

import alertTemplatesAdd from "@/modules/alertTemplates/add/_store";
import alertTemplatesList from "@/modules/alertTemplates/list/_store";
import alertTemplatesEdit from "@/modules/alertTemplates/edit/_store";

import alertsList from "@/modules/alerts/list/_store";
import alertsDashboard from "@/modules/alerts/dashboard/_store";
import alertsReminders from "@/modules/alerts/reminders/_store";

import conversationsList from "@/modules/conversations/list/_store";
import conversationsAdd from "@/modules/conversations/add/_store/index.admin";
import conversationsView from "@/modules/conversations/view/_store";

import common from "@/modules/common/_store";
import list from "@/mixins/list/_store";

import documentsList from "@/modules/documents/list/_store";

import exportMouvementsStock from "@/modules/exports/mouvements-stock/_store";
import exportDeliveredPairableDevices from "@/modules/exports/delivered-pairable-devices/_store";
import exportConsommations from "@/modules/exports/consommations/_store";

import entityInterventions from "@/modules/entity-interventions/list/_store";
import entityInterventionView from "@/modules/entity-interventions/view/_store";

import renewalsUnlinked from "@/modules/renewals-unlinked/_store";

import exports from "@/modules/exports/get-export/_store";

import publicationsAdd from "@/modules/publications/add/_store";
import publicationsEdit from "@/modules/publications/edit/_store";
import publicationsList from "@/modules/publications/list/_store";

import reportAdd from "@/modules/reports/add/_store";
import reportView from "@/modules/reports/view/_store";

import contactsAdd from "@/modules/contacts/add/_store";
import contactsList from "@/modules/contacts/list/_store";
import contactsView from "@/modules/contacts/view/_store";
import contactsEdit from "@/modules/contacts/edit/_store";

import reminders from "@/modules/reminders/_store";

import faxes from "@/modules/faxes/_store";
import maintenance from "@/modules/maintenance/_store";

import renewals from "@/modules/renewals/_store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    caisses,
    credentials,
    login,
    dashboard,
    patients,
    versionManager,
    ordoclic,
    supportLogs,
    appInfos,
    supportTickets,
    supportTicketsAdd,
    supportTicketsView,
    payment,
    patientLiftFormView,
    patientView,
    patientAdd,
    patientPlanification,
    patientSearch,
    prescribersList,
    hsat,
    patientOrders,
    patientEdit,
    prescriberAdd,
    prescriberView,
    prescriberEdit,
    noticesAdd,
    noticesList,
    communityAdd,
    communities,
    interventions,
    interventionsPlanning,
    interventionView,
    interventionsTour,
    interventionsCalendar,
    interventionsCommon,
    prescribersGroups,
    prescribersGroupAdd,
    prescribersGroupEdit,
    prescribersGroupView,
    communityView,
    communityEdit,
    usersList,
    usersView,
    usersAdd,
    usersEdit,
    products,
    productView,
    productEdit,
    productsStock,
    hsatStocks,
    gasOxygenMonitoring,
    gasOxygenHistory,
    liquidOxygenMonitoring,
    liquidOxygenFraction,
    contractsAdd,
    aald,
    alertTemplatesAdd,
    alertTemplatesList,
    alertTemplatesEdit,
    alertsList,
    alertsDashboard,
    alertsReminders,
    conversationsList,
    conversationsAdd,
    conversationsView,
    common,
    documentsList,
    list,
    exportMouvementsStock,
    exportDeliveredPairableDevices,
    exportConsommations,
    entityInterventions,
    entityInterventionView,
    renewalsUnlinked,
    exports,
    synchroStatuses,
    publicationsAdd,
    publicationsEdit,
    publicationsList,
    reportAdd,
    reportView,
    contactsAdd,
    contactsList,
    contactsView,
    contactsEdit,
    communitiesGroups,
    communitiesGroupAdd,
    communitiesGroupView,
    communitiesGroupEdit,
    reminders,
    faxes,
    maintenance,
    renewals
  }
});
