/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import eachSeries from "async/eachSeries";
import moment from "moment";
import isNil from "lodash/isNil";

import defaultSuccessMessages from "@/helpers/_constants/defaultSuccessMessages";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";

import {
  searchProducts,
  fetchTelesuivi,
  fetchDeliveries,
  fetchAgaProductInformations,
  fetchEquipementAvailability,
  pairEquipment,
  unPairEquipment
} from "@/modules/common/_api";

import * as api from "../_api";

const deliveryTypeIds = require("@common/constants/deliveryTypeIds");
const deliveryStatuses = require("@common/constants/deliveryStatuses");
const interventionStatuses = require("@common/constants/interventionStatuses");

const features = require("@/services/features");

/* eslint-disable no-shadow */

const state = {
  deliveries: [],
  poleStock: null,
  taxonomies: {
    deviceModes: [],
    deviceSettings: [],
    deviceMetrics: []
  },
  feedbackMessages: {
    caution: null,
    errorTelesuivi: null,
    errorFilePreview: null,
    errorEquipement: null,
    errorAppairage: null,
    equipementAvailabilityCaution: null
  },
  loading: {
    replacement: false,
    telesuivi: false,
    appairage: false
  }
};

const getters = {
  deliveries: state => state.deliveries
};

const mutations = {
  SET_DELIVERIES: (state, deliveries) => {
    state.deliveries = deliveries;
  },
  SET_STOCKS_SEARCH_RESULTS: (state, products) => {
    state.stocksSearchResults = products;
  },
  SET_EQUIPEMENT_INFORMATIONS: (state, equipementInformations) => {
    state.equipementInformations = equipementInformations;
  },
  UPDATE_DELIVERY_STORE: (state, updatedDelivery) => {
    const deliveryIndex = state.deliveries.findIndex(delivery => delivery._id === updatedDelivery._id);

    if (deliveryIndex >= 0) {
      state.deliveries[deliveryIndex].show = updatedDelivery.show;
      state.deliveries[deliveryIndex].quantity = updatedDelivery.quantity;
      state.deliveries[deliveryIndex].serialNumber = updatedDelivery.serialNumber;
      state.deliveries[deliveryIndex].batchNumber = updatedDelivery.batchNumber;
      state.deliveries[deliveryIndex].agaNumber = updatedDelivery.agaNumber;
    }
  }
};

const actions = {
  fetchDeliveries(context) {
    context.commit("UPDATE_LOADING", { deliveries: true });
    const { intervention, patient } = context.state;
    const query = {
      patientId: intervention.patientId,
      statusIds: [
        deliveryStatuses.waiting,
        deliveryStatuses.pendingPreparation,
        deliveryStatuses.pendingDelivery,
        deliveryStatuses.delivered,
        deliveryStatuses.removed,
        deliveryStatuses.toBeRemoved
      ],
      typeId: deliveryTypeIds.product
    };

    const brContract = patient.contracts?.find(contract => intervention.contractId === contract.id && contract.brContractId);

    if (brContract) {
      query.brContractId = brContract.brContractId;
    }

    if (intervention.contractId) {
      query.contractId = intervention.contractId;
    }

    return new Promise((resolve, reject) => {
      fetchDeliveries({ query })
        .then(res => {
          context.commit("SET_DELIVERIES", res.data.body.results || []);

          resolve();
        })
        .catch(err => {
          context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });

          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { deliveries: false });
        });
    });
  },
  createDeliveries(context, items) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { submit: true });
      context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });
      const deliveriesToCreate = [];

      items.forEach(item => {
        const delivery = {
          productId: item.productId,
          quantity: item.quantity,
          show: item.product.showOnTour
        };

        if (features.isStockHandlerEnabled()) {
          delivery.stockId = item.stockId || context.state.intervention?.stockId || null;
        }

        if (item.serialNumber) {
          delivery.serialNumber = item.serialNumber;
        }

        if (item.batchNumber) {
          delivery.batchNumber = item.batchNumber;
        }

        if (item.fabricantId) {
          delivery.fabricantId = item.fabricantId;
        }

        if (item.agaNumber) {
          delivery.agaNumber = item.agaNumber;
        }

        if (item.isSample) {
          delivery.isSample = item.isSample;
          delivery.noStockMovement = true;
        }

        deliveriesToCreate.push(delivery);
      });

      eachSeries(
        deliveriesToCreate,
        (delivery, nextDelivery) => {
          api
            .createDelivery({
              ...delivery,
              statusId: [interventionStatuses.toBePrepared, interventionStatuses.toBeDelivered].includes(context.state.intervention.statusId)
                ? deliveryStatuses.pendingPreparation
                : deliveryStatuses.waiting,
              patientId: context.state.intervention.patientId,
              contractId: context.state.intervention.contractId,
              deliveryInterventionId: context.state.intervention._id
            })
            .then(() => nextDelivery())
            .catch(err => nextDelivery(err));
        },
        errLoopDeliveries => {
          context.commit("UPDATE_LOADING", { submit: false });

          if (errLoopDeliveries) {
            context.dispatch("updateFeedbackMessages", { error: errLoopDeliveries.data.errorMessage });

            return reject(errLoopDeliveries);
          }

          context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.createDelivery });
          context.dispatch("fetchDeliveries");
          resolve();
        }
      );
    });
  },
  removeProduct(context, { deliveryId, recuperationMotifId }) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { submit: true });
      context.dispatch("updateFeedbackMessages", { error: null, success: null, caution: null });
      const interventionId = context.state.intervention._id;
      const { product, status, stock, fractions, ...delivery } = context.state.deliveries.find(delivery => delivery._id === deliveryId);

      /* Waiting delivery should be archived, others should be updated to "toBeRemoved" */
      const newStatus = [deliveryStatuses.waiting, deliveryStatuses.pendingPreparation, deliveryStatuses.pendingDelivery].includes(delivery.statusId)
        ? deliveryStatuses.archived
        : deliveryStatuses.toBeRemoved;

      /**
       * We only need to add the intervention's id that removed the product if the product was delivered.
       * Otherwise, the product will be archived and we don't need to track the intervention's id that archived this product.
       */
      const removalInterventionId = newStatus === deliveryStatuses.toBeRemoved ? interventionId : null;
      const updatedDelivery = {
        ...delivery,
        statusId: newStatus,
        removalInterventionId
      };
      if (recuperationMotifId) {
        updatedDelivery.recuperationMotifId = recuperationMotifId;
      }
      if (updatedDelivery.show === null) {
        updatedDelivery.show = false;
      }
      api
        .updateDelivery(deliveryId, updatedDelivery)
        .then(() => {
          context.dispatch("fetchDeliveries");
          const deliveryContract = context.state.patient.contracts.find(contract => contract.id === delivery.contractId);
          const isProductPaired =
            delivery.statusId === deliveryStatuses.delivered &&
            delivery.serialNumber &&
            deliveryContract.telesuiviAppairage &&
            product.telesuivi &&
            deliveryContract.telesuiviFabricantId === delivery.fabricantId;
          if (isProductPaired) {
            context
              .dispatch("removeAppairage", delivery)
              .then(() => {
                context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.removeAppairage });
              })
              .catch(err => {
                context.dispatch("updateFeedbackMessages", {
                  caution: err?.data?.errorMessage || defaultErrorMessages.removeAppairage
                });
              })
              .finally(() => resolve());
          } else {
            context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.removeProduct });
            resolve();
          }
        })
        .catch(err => {
          reject(err);
          context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });
    });
  },
  replaceProduct(context, payload) {
    context.commit("UPDATE_LOADING", { replacement: true });
    context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });
    const { deliveryToReplace, newDeliveries } = payload;
    Promise.all([context.dispatch("removeProduct", { deliveryId: deliveryToReplace._id }), context.dispatch("createDeliveries", newDeliveries)])
      .then(() => {
        context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.replaceProduct });
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { replacement: false });
      });
  },
  updateDeliverySerialNumber(context, { delivery, serialNumber }) {
    context.commit("UPDATE_LOADING", { intervention: true });
    context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });

    if (features.isStockHandlerEnabled()) {
      context
        .dispatch("common/checkSerialNumberValidity", serialNumber, { root: true })
        .then(equipement => {
          /* If the serial number is known, SAP should return the associated product ID and optionally the fabricant ID */
          if (equipement && equipement.productId) {
            delivery.serialNumber = serialNumber;
            delivery.fabricantId = equipement.fabricantId || null;
          } else {
            /* Else, the serial number is unknown so we invalid it */
            delivery.serialNumber = null;
            delivery.fabricantId = null;

            context.dispatch("updateFeedbackMessages", {
              errorForm: defaultErrorMessages.unknownSAP(serialNumber)
            });
            context.commit("UPDATE_LOADING", { intervention: false });

            /* Il n'y a plus d'interet à update les product settings */
            return;
          }

          if (!delivery.deliveryId) {
            delivery.deliveryId = delivery._id;
          }

          context
            .dispatch("updateProductSettings", delivery)
            .then(() => {
              context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.updateLegacyConsommables });
            })
            .catch(() => {
              context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateLegacyConsommables });
            })
            .finally(() => {
              context.commit("UPDATE_LOADING", { intervention: false });
            });
        })
        .catch(() => {
          context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateEntityDelivery });
          context.commit("UPDATE_LOADING", { intervention: false });
        });
    } else {
      delivery.serialNumber = serialNumber;
      delivery.fabricantId = serialNumber; // Pour intus et medpro le fabricantId = serialNumber, ils utilisent exclusivent le fabricantId.
      delivery.deliveryId = delivery._id;

      context.dispatch("updateProductSettings", delivery).then(() => {
        context.commit("UPDATE_LOADING", { intervention: false });
      });
    }
  },
  updateDelivery(context, update) {
    context.commit("UPDATE_LOADING", { intervention: true });
    context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });

    const { deliveryId, serialNumber, agaNumber } = update;
    const { statusId } = context.state.deliveries.find(delivery => delivery._id === deliveryId);

    /* We only need to check serial and aga numbers for waiting deliveries */
    if (statusId !== deliveryStatuses.waiting) {
      context.dispatch("updateProductSettings", update);
      return;
    }
    if (serialNumber) {
      if (features.isStockHandlerEnabled()) {
        context
          .dispatch("common/checkSerialNumberValidity", serialNumber, { root: true })
          .then(equipement => {
            /* If the serial number is known, SAP should return the associated product ID and optionally the fabricant ID */
            if (equipement && equipement.productId) {
              update.fabricantId = equipement.fabricantId || null;
            } else {
              /* Else, the serial number is unknown so we invalid it */
              update.serialNumber = null;
              update.fabricantId = null;

              context.dispatch("updateFeedbackMessages", {
                errorForm: defaultErrorMessages.unknownSAP(serialNumber)
              });
            }
            context.dispatch("updateProductSettings", update);
          })
          .catch(() => {
            context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateEntityDelivery });
            context.commit("UPDATE_LOADING", { intervention: false });
          });
      } else {
        update.serialNumber = serialNumber;
        update.fabricantId = serialNumber; // Pour intus et medpro le fabricantId = serialNumber, ils utilisent exclusivent le fabricantId.

        context.dispatch("updateProductSettings", update).then(() => {
          context.commit("UPDATE_LOADING", { intervention: false });
        });
      }
    } else if (agaNumber) {
      context
        .dispatch("checkAgaNumberValidity", agaNumber)
        .then(batchNumber => {
          /* If the AGA number is known, SAP should return the associated batch number */
          if (batchNumber) {
            update.batchNumber = batchNumber;
          } else {
            /* Else, the AGA number is unknown so we invalid it */
            update.agaNumber = null;
            update.batchNumber = null;

            context.dispatch("updateFeedbackMessages", {
              errorForm: defaultErrorMessages.unknownAGA(agaNumber)
            });
          }
          context.dispatch("updateProductSettings", update);
        })
        .catch(() => {
          context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateEntityDelivery });
          context.commit("UPDATE_LOADING", { intervention: false });
        });
    } else {
      context.dispatch("updateProductSettings", update);
    }
  },
  updateProductSettings(context, update) {
    return new Promise((resolve, reject) => {
      const {
        deliveryId,
        forfaitId,
        quantity,
        serialNumber,
        fabricantId,
        batchNumber,
        agaNumber,
        show,
        mode,
        settings,
        metrics,
        telesuiviConsent,
        fraction,
        productOrigin
      } = update;

      /* 1. First we update delivery related settings */
      const { product, status, stock, telesuivi, fractions, ...delivery } = context.state.deliveries.find(delivery => delivery._id === deliveryId);
      const isDM = product.typeId === "machine";
      const shouldBeShown = !isNil(show) ? show : isDM;

      const updatedDelivery = {
        ...delivery,
        quantity,
        show: shouldBeShown
      };

      if (delivery.legacy) {
        api
          .updateLegacyConsommables(deliveryId, updatedDelivery)
          .then(() => {
            context.commit("UPDATE_DELIVERY_STORE", updatedDelivery);

            context.dispatch("fetchInterventionView", context.state.rawIntervention._id).finally(() => {
              context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.updateLegacyConsommables });
            });
          })
          .catch(() => {
            context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateLegacyConsommables });
          })
          .finally(() => {
            context.commit("UPDATE_LOADING", { intervention: false });
          });
      } else {
        updatedDelivery.serialNumber = serialNumber || null;
        updatedDelivery.fabricantId = fabricantId || null;
        updatedDelivery.batchNumber = batchNumber || null;
        updatedDelivery.agaNumber = agaNumber || null;
        updatedDelivery.forfaitId = forfaitId;
        updatedDelivery.productOrigin = productOrigin || undefined;

        if (fraction) {
          updatedDelivery.fraction = fraction;
        }

        api
          .updateDelivery(deliveryId, updatedDelivery)
          .then(() => {
            context.commit("UPDATE_DELIVERY_STORE", updatedDelivery);

            const intervention = context.state.rawIntervention;

            /* Only DM have settings and metrics */
            if (!isDM) {
              context.dispatch("patientEdit/editContract", { telesuiviConsent, id: delivery.contractId }, { root: true }).finally(() => {
                context.commit("UPDATE_LOADING", { intervention: false });
              });
              resolve();
              return;
            }

            /* 2. Then we update intervention related settings */

            /* Transform settings & metrics from array to object */
            const formattedSettings =
              settings && settings.length
                ? settings.reduce((acc, setting) => {
                    acc[setting.settingId] = setting.settingValue;
                    return acc;
                  }, {})
                : null;

            const formattedMetrics =
              metrics && metrics.length
                ? metrics.reduce((acc, metric) => {
                    acc[metric.metricId] = metric.metricValue;
                    return acc;
                  }, {})
                : null;

            if (formattedMetrics && formattedMetrics.computedObs && !formattedMetrics.obs) {
              formattedMetrics.obs = formattedMetrics.computedObs;
            }

            /**
             * They are 3 cases for metrics/settings edition :
             * 1. We edit metrics/settings on an already registered appareillage
             * 2. We add metrics/settings for the first time for this product so we add a new appareillage
             * 3. Same case as 2 but this is the appareillage for this intervention
             */
            const existingAppareillageIndex =
              intervention.appareillages && intervention.appareillages.length
                ? intervention.appareillages.findIndex(appareillage => appareillage.deliveryId === deliveryId)
                : -1;

            if (existingAppareillageIndex !== -1) {
              intervention.appareillages[existingAppareillageIndex] = {
                ...intervention.appareillages[existingAppareillageIndex],
                mode,
                treatmentTypeId: product.treatmentTypeId,
                settings: formattedSettings,
                metrics: formattedMetrics
              };
            } else if (intervention.appareillages) {
              intervention.appareillages.push({
                deliveryId,
                mode,
                treatmentTypeId: product.treatmentTypeId,
                settings: formattedSettings,
                metrics: formattedMetrics
              });
            } else {
              intervention.appareillages = [
                { deliveryId, mode, treatmentTypeId: product.treatmentTypeId, settings: formattedSettings, metrics: formattedMetrics }
              ];
            }

            api
              .editIntervention(intervention._id, intervention)
              .then(() => {
                context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.updateLegacyConsommables });
                context.dispatch("fetchInterventionView", intervention._id);
              })
              .catch(() => {
                context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateLegacyConsommables });

                reject();
              })
              .finally(() => {
                context.dispatch("patientEdit/editContract", { telesuiviConsent, id: intervention.contractId }, { root: true }).finally(() => {
                  context.commit("UPDATE_LOADING", { intervention: false });

                  resolve();
                });
              });
          })
          .catch(() => {
            context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateLegacyConsommables });
            context.commit("UPDATE_LOADING", { intervention: false });
          });
      }
    });
  },
  searchProducts(context, input) {
    return new Promise((resolve, reject) => {
      searchProducts({
        query: { input },
        skip: 0,
        limit: 50,
        source: "local"
      })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkAgaNumberValidity(context, agaNumber) {
    return new Promise(resolve => {
      fetchAgaProductInformations({ agaNumber })
        .then(resGetAgaProduct => {
          const agaProduct = resGetAgaProduct.data && resGetAgaProduct.data.body ? resGetAgaProduct.data.body : null;

          if (!agaProduct || !agaProduct.batchNumber) {
            return resolve(null);
          }

          return resolve(agaProduct.batchNumber);
        })
        .catch(() => resolve(null));
    });
  },
  sendOrder(context) {
    context.commit("UPDATE_LOADING", { submit: true });
    context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });

    api
      .sendOrder(context.state.intervention._id)
      .then(res => {
        context.dispatch("updateFeedbackMessages", { success: res.data.successMessage });
        context.dispatch("fetchDeliveries");
      })
      .catch(err => {
        context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { submit: false });
      });
  },
  fetchTelesuivi(context, fabricantId) {
    context.commit("UPDATE_LOADING", { telesuivi: true });
    context.dispatch("updateFeedbackMessages", { error: null, success: null, errorTelesuivi: null });

    fetchTelesuivi({
      query: {
        fabricantId,
        patientId: context.state.intervention.patientId,
        startDate: moment().subtract(11, "months").startOf("month").toISOString(),
        endDate: moment().toISOString()
      }
    })
      .then(res => {
        const telesuivi = res && res.data ? res.data.body : [];
        const deliveries = context.state.deliveries.map(delivery => {
          if (delivery.fabricantId === fabricantId) {
            delivery.telesuivi = telesuivi;
          }

          return delivery;
        });

        context.commit("SET_DELIVERIES", deliveries);
      })
      .catch(err => {
        context.dispatch("updateFeedbackMessages", { errorTelesuivi: err.data.errorMessage });
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { telesuivi: false });
      });
  },
  updateProductFiles(context, data) {
    context.commit("UPDATE_LOADING", { submit: true });
    context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });

    const { deliveryId, productFiles } = data;
    const { rawIntervention } = context.state;
    /* We need to remove files from the given deliveryId to avoid duplication */
    const alreadyExitingFiles = rawIntervention.productFiles ? rawIntervention.productFiles.filter(file => file.deliveryId !== deliveryId) : [];
    productFiles.forEach(file => {
      file.deliveryId = deliveryId;
    });
    alreadyExitingFiles.push(...productFiles);

    api
      .editIntervention(rawIntervention._id, { ...rawIntervention, productFiles: alreadyExitingFiles })
      .then(() => {
        context.commit("SET_INTERVENTION", { ...context.state.intervention, productFiles: alreadyExitingFiles });
        context.commit("SET_RAW_INTERVENTION", { ...context.state.rawIntervention, productFiles: alreadyExitingFiles });

        context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.editIntervention });
        context.dispatch("fetchDeliveries");
      })
      .catch(err => {
        context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { submit: false });
      });
  },
  fetchEquipementAvailability(context, params) {
    return new Promise((resolve, reject) => {
      fetchEquipementAvailability(params)
        .then(response => resolve(response.data.body.available))
        .catch(err => reject(err));
    });
  },
  startAppairage(context, delivery) {
    context.commit("UPDATE_LOADING", { appairage: true });
    context.dispatch("updateFeedbackMessages", { errorEquipement: null });

    context
      .dispatch("common/getEquipementInformations", delivery, { root: true })
      .then(equipementInformations => {
        const { manufacturer, fabricantId } = equipementInformations;
        const deliveryContract = context.state.patient.contracts.find(contract => contract.id === delivery.contractId);

        context
          .dispatch("fetchEquipementAvailability", {
            manufacturer,
            fabricantId,
            contractId: deliveryContract?.id,
            channelId: deliveryContract?.channelId
          })
          .then(available => {
            if (!available) {
              context.dispatch("updateFeedbackMessages", {
                equipementAvailabilityCaution: defaultSuccessMessages.equipementAvailabilityCaution
              });
            }
          })
          .catch(err => {
            context.dispatch("updateFeedbackMessages", {
              errorEquipement: err?.data?.errorMessage || defaultErrorMessages.default
            });
          });
      })
      .catch(() => {
        context.dispatch("updateFeedbackMessages", {
          errorEquipement: defaultErrorMessages.equipementAvailabilityCaution
        });
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { appairage: false });
      });
  },
  validateAppairage(context, payload) {
    context.commit("UPDATE_LOADING", { appairage: true });
    context.dispatch("updateFeedbackMessages", { errorAppairage: null, equipementAvailabilityCaution: null });

    const { channelId, intervenantId } = context.state.intervention;

    pairEquipment({ ...payload, intervenantId, channelId })
      .then(() => {
        context.dispatch("fetchPatient", context.state.patient._id).finally(() => {
          context.commit("UPDATE_LOADING", { appairage: false });
        });
      })
      .catch(errAppairage => {
        context.dispatch("updateFeedbackMessages", { errorAppairage: errAppairage?.data?.errorMessage || defaultErrorMessages.default });
        context.commit("UPDATE_LOADING", { appairage: false });
      });
  },
  removeAppairage(context, delivery) {
    const deliveryContract = context.state.patient.contracts.find(contract => contract.id === delivery.contractId);

    return new Promise((resolve, reject) => {
      unPairEquipment({ delivery, channelId: deliveryContract?.channelId })
        .then(() => resolve())
        .catch(errDesappairage => reject(errDesappairage));
    });
  },
  cancelDeliveryRemoval(context, delivery) {
    return new Promise((resolve, reject) => {
      api
        .cancelDeliveryRemoval(delivery._id)
        .then(() => {
          if (delivery.product.telesuivi) {
            context.dispatch("updateFeedbackMessages", {
              caution: defaultErrorMessages.cancelDeliveryRemoval(delivery.product.label)
            });
          }
          context.dispatch("fetchDeliveries");
        })
        .catch(errCancelRemovalDelivery => {
          context.dispatch("updateFeedbackMessages", { errorEquipement: errCancelRemovalDelivery.data.errorMessage });
          reject(errCancelRemovalDelivery);
        });
    });
  }
};

export default { state, getters, mutations, actions };
